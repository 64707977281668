import React from "react"

import Layout from "./layout"
import SEO from "../../components/seo"
import PostList from "gatsby-theme-blog/src/components/post-list"

type PostsProp = {
  posts: ReadonlyArray<any>
}

const Posts = ({ posts }: PostsProp): JSX.Element => (
  <Layout>
    <SEO title="Blog" />
    <h1 className="blog-title">Blog</h1>
    <main>
      <PostList posts={posts} />
    </main>
  </Layout>
)

export default Posts
